import { axios } from '@/utils/request'

/**
 * 增加参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function addDir (parameter) {
  return axios({
    // url: '/param/addDir',
    url: '/dict/addType',
    method: 'post',
    data: parameter
  })
}

/**
 * 增加参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function add (parameter) {
  return axios({
    // url: '/param/add',
    url: '/dict/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function editDir (parameter) {
  return axios({
    // url: '/param/editDir',
    url: '/dict/editType',
    method: 'post',
    data: parameter
  })
}
/**
 * 编辑参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function edit (parameter) {
  return axios({
    // url: '/param/edit',
    url: '/dict/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function delDir (parameter) {
  return axios({
    // url: '/param/delDir',
    url: '/dict/delType',
    method: 'post',
    params: parameter
  })
}
/**
 * 删除参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function del (params) {
  return axios({
    // url: '/param/del',
    url: '/dict/del',
    method: 'post',
    params: params
  })
}
/**
 * 参数分类树
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getDirTree (params) {
  return axios({
    url: '/dict/getTypeTree',
    method: 'get',
    params: params
  })
}
/**
 * 获取所有参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getAll (parameter) {
  return axios({
    // url: '/param/getAll',
    url: '/dict/getAll',
    method: 'get',
    params: parameter
  })
}

// 编辑状态
export function editStatus (parameter) {
  return axios({
    url: '/dict/editStatus',
    method: 'post',
    data: parameter
  })
}

/**
 * 获取单个分类信息（根据dictKey）
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getTypeByDictKey (parameter) {
  return axios({
    url: 'dict/getTypeByDictKey',
    method: 'get',
    params: parameter
  })
}
